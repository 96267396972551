/**
 * File navigation-buttons.js.
 *
 * Handles toggling the navigation menu children for the mobile menu.
 */
 jQuery(".header-second nav.main-navigation ul li button").click(function (e) {
     e.preventDefault();

     jQuery(this).closest("li").toggleClass("is-active");
 });

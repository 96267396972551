equalheight = function (container) {
    if (jQuery(window).width() > 1024) {

        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            $el,
            topPosition = 0;
        jQuery(container).each(function () {

            $el = jQuery(this);
            jQuery($el).height('auto');
            topPostion = $el.position().top;

            if (currentRowStart != topPostion) {
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                    rowDivs[currentDiv].innerHeight(currentTallest);
                }
                rowDivs.length = 0; // empty the array
                currentRowStart = topPostion;
                currentTallest = $el.innerHeight();
                rowDivs.push($el);
            } else {
                rowDivs.push($el);
                currentTallest = (currentTallest < $el.innerHeight()) ? ($el.innerHeight()) : (currentTallest);
            }
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].innerHeight(currentTallest);
            }
        });

    } else {
        jQuery(container).height('auto');
    }

};

jQuery(document).ready(function() {
    var slideshow = new Swiper(".swiper-container", {
        autoplay:   5000,
        loop:       true,
        nextButton: ".swiper-button-next",
        prevButton: ".swiper-button-prev",
    });

    equalheight('.full-heigth');
    jQuery(".mobile-icon a").on("click touchstart",function(event){
        event.preventDefault();
        event.stopPropagation();
        jQuery("body").toggleClass("menu-open");
        jQuery(".fa-bars").toggleClass("fa-chevron-right");

    });

	equalheight('.img-height');
	equalheight('.block-height');
});

jQuery(window).resize(function() {
	equalheight('.full-heigth');
	equalheight('.img-height');
	equalheight('.block-height');
});

jQuery(window).load(function() {
	equalheight('.full-heigth');
	equalheight('.img-height');
	equalheight('.block-height');
});
